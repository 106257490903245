import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

// import banner from '../../Assets/Images/banner.png';
import deliveryMan from "../../Assets/Images/delivery.png";
import pickupMain from "../../Assets/Images/pickup.png";
import stopwatch from "../../Assets/Images/stopwatch1.png";
import discount1 from "../../Assets/Images/discount1.png";
import Item1 from "../../Assets/Images/item1.png";
import vegetarian from "../../Assets/Images/vegetarian.png";
import menu from "../../Assets/Images/menu.png";
import data from "../../store_menu_details.json";

import "./index.css";

const item = ["DELIVERY"];
const delivery = item.map((item) => (
   <>
      <img src={deliveryMan} alt="delivery" />
      <li>{item}</li>
   </>
));

const itemup = ["PICK UP"];
const pickup = itemup.map((itemup) => (
   <>
      <img src={pickupMain} alt="pickupMain" />
      <li>{itemup}</li>
   </>
));

const tsxPizza = data.store_details;

let list = [];
const storeMenuList = data.store_menu.items;
for (var k of Object.values(storeMenuList)) {
   list.push(k);
}

const Menu = () => {
   return (
      <div className="menuWrapper">
         <div className="menuBanner">
            <img src={tsxPizza.store_image_url} alt="banner" />
         </div>

         <div className="deliveryMode">
            <h2>{tsxPizza.store_name}</h2>
            <div className="modeTab">
               <Tabs defaultActiveKey="delivery" id="uncontrolled-tab-example">
                  <Tab eventKey="delivery" title={delivery}>
                     <div className="deliveryTime">
                        <div className="timeMode">
                           <img src={stopwatch} alt="time" />
                           <p>{tsxPizza.store_prep_time_in_mins} mins</p>
                        </div>
                        <div className="timeMode">
                           <img src={deliveryMan} alt="shipping" />
                           <p>&#x20B9; 20</p>
                        </div>
                        <div className="timeMode">
                           <img src={discount1} alt="discount" />
                           <p>{tsxPizza.store_discount_percent} %</p>
                        </div>
                     </div>
                  </Tab>
                  <Tab eventKey="pickup" title={pickup}>
                     <div className="deliveryTime">
                        <div className="timeMode">
                           <img src={stopwatch} alt="time" />
                           <p>25 mins</p>
                        </div>
                        <div className="timeMode">
                           <img src={discount1} alt="discount" />
                           <p>Discounts</p>
                        </div>
                     </div>
                  </Tab>
               </Tabs>
            </div>
            <div className="menuHours">
               <p>Menu Hours: 10:00 AM to 11:00 PM</p>
            </div>
         </div>

         <div className="menudetailMain">
            <h3 className="menu_heading">Pizza</h3>
            {list.map((data) => {
               return (
                  <div className="menuDetails">
                     <div className="menuList">
                        <div className="menuinfo">
                           <div className="itemList">
                              <div>
                                 <h6>{data.name}</h6>
                                 <p>{data.description}</p>
                              </div>
                              <div className="itemPrice">
                                 <img src={vegetarian} alt="veg" />
                                 <h2>&#x20B9; {data?.price?.amoun}</h2>
                              </div>
                           </div>
                        </div>
                        <div className="menuimage">
                           <div className="itemImage">
                              <img src={Item1} alt="pizza" />
                              <button>+ ADD</button>
                           </div>
                        </div>
                     </div>
                  </div>
               );
            })}
         </div>

         <div className="menuBar">
            <div className="menubottom">
               <img src={menu} alt="menuIcon" />
               <p>Menu</p>
            </div>
         </div>
         <div className="viewCart">
            <button>VIEW CART</button>
         </div>
      </div>
   );
};

export default Menu;
